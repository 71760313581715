@use '../../settings/_variable' as v;
@use '../../settings/_mixin' as r;
@use '../../settings/_path' as p;

.p-top {
  main {
    position: relative;
    &:before {
      content: '';
      display: block;
      width: 100vw;
      height: 100vh;
      background-image: url(/assets/images/top/mv.png);
      background-repeat: no-repeat;
      background-size: cover;
      position: fixed;
      top: 0;
      left: 0;
      z-index: -1;
      @include r.sp {
        background-image: url(/assets/images/top/mv_sp.png);
      }
    }
  }
  .c-mv {
    height: 100vh;
    position: relative;
    @include r.sp {
      height: 550px;
    }
    &__txt {
      position: absolute;
      bottom: calc(120 / 1920 * 100vw);
      left: calc(120 / 1920 * 100vw);
      @include r.sp {
        bottom: 0;
        left: 0;
      }
      span {
        display: inline-block;
        font-size: 4rem;
        font-weight: bold;
        letter-spacing: 0.05em;
        color: #fff;
        background: #000;
        padding: 20px 40px;
        @include r.pad {
          font-size: 3rem;
        }
        @include r.sp {
          font-size: 2.4rem;
          padding: 10px 20px;
        }
      }
    }
  }
  &1 {
    background: rgba($color: #fff, $alpha: 0.8);
    padding: 160px 0;
    position: relative;
    @include r.sp {
      padding: 60px 0;
    }
    .inner {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      gap: 80px;
      position: relative;
      @include r.sp {
        flex-direction: column;
        gap: 40px;
      }
      &:before {
        content: '';
        display: inline-block;
        width: 700px;
        height: 700px;
        background: url(/assets/images/common/triangle_white.svg);
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        top: -78px;
        left: 0;
        transform: translateX(-5%);
        z-index: -1;
        @include r.sp {
          width: 280px;
          height: 280px;
          top: -20px;
          transform: translateX(20%);
        }
      }
      .c-ttl2 {
        font-size: 4rem;
        line-height: calc(7 / 4);
        @include r.pad {
          font-size: 3rem;
        }
        @include r.sp {
          font-size: 2.4rem;
          line-height: calc(36 / 24);
        }
      }
    }
    &__txt {
      width: calc(560 / 1200 * 100%);
      font-size: 1.8rem;
      font-weight: bold;
      line-height: 2;
      @include r.sp {
        width: 100%;
      }
    }
    &__list {
      display: flex;
      @include r.sp {
        overflow-x: scroll;
      }
      li {
        width: 25%;
        @include r.sp {
          width: 50%;
          flex-shrink: 0;
        }
      }
    }
  }
  &2 {
    background: #f6f8f8;
    padding: 160px 0;
    @include r.sp {
      padding: 60px 0;
    }
    .inner {
      display: flex;
      justify-content: space-between;
      gap: 80px;
      @include r.sp {
        flex-direction: column;
        gap: 40px;
      }
      .c-ttl1 {
        font-size: 6rem;
        @include r.pad {
          font-size: 5rem;
        }
        @include r.sp {
          font-size: 4rem;
        }
        &__ja {
          font-size: 2rem;
          line-height: calc(3 / 2);
          margin-top: 16px;
          @include r.sp {
            margin-top: 10px;
          }
          &:before {
            width: 16px;
            height: 20px;
          }
        }
      }
      .news {
        &__list {
          width: calc(800 / 1200 * 100%);
          max-height: calc(191px * 3);
          overflow-y: scroll;
          @include r.sp {
            width: 100%;
          }
        }
        &__item {
          border-top: 1px solid rgba($color: #000000, $alpha: 0.2);
          padding: 19px 30px 19px 0;
          @include r.sp {
            max-height: 386px;
            padding: 20px 0 42px;
          }
          &:last-child {
            border-bottom: 1px solid rgba($color: #000000, $alpha: 0.2);
          }
        }
        &__date {
          font-family: 'Barlow', sans-serif;
          font-size: 1.6rem;
          color: #c8161e;
        }
        &__ttl {
          font-size: 1.6rem;
          font-weight: bold;
          line-height: calc(28 / 16);
          margin-top: 17px;
        }
        &__txt {
          font-size: 1.6rem;
          font-weight: bold;
          line-height: calc(28 / 16);
          margin-top: 19px;
          @include r.sp {
            margin-top: 17px;
          }
        }
      }
    }
  }
  &3 {
    background: #fff;
    padding: 160px 0;
    position: relative;
    @include r.sp {
      padding: 60px 0;
    }
    &:before {
      content: '';
      display: inline-block;
      width: calc(960 / 1920 * 100vw);
      height: 100%;
      background: url(/assets/images/common/triangle.svg);
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      top: 82px;
      right: 0;
      transform: translateX(calc(320 / 1920 * 100vw));
      @include r.sp {
        width: calc(320 / 378 * 100vw);
        top: 32px;
        transform: translateX(calc(80 / 320 * 100vw));
      }
    }
    .inner {
      position: relative;
    }
    .nolink {
      display: none !important;
      background: gray;
      border: 1px solid transparent;
      opacity: 0.4;
    }
    &__link {
      display: inline-flex;
      align-items: center;
      gap: 12px;
      font-weight: bold;
      border: 1px solid #000;
      border-radius: 40px;
      padding: 20px 40px;
      margin-top: 15px;
      @include r.sp {
        gap: 10px;
        font-size: 1.2rem;
        padding: 17px 20px;
      }
      &:after {
        content: '→';
      }
      &:hover {
        color: #c8161e;
        background: rgba($color: #c8161e, $alpha: 0.1);
        border-color: transparent;
      }
    }
    &__index {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      margin-top: 80px;
      @include r.sp {
        gap: 10px 5px;
        margin-top: 40px;
      }
      &__item {
        display: block;
        width: 100%;
        height: 100%;
        font-size: 1.6rem;
        font-weight: bold;
        letter-spacing: 0.05em;
        border: 1px solid #000;
        border-radius: 40px;
        padding: 20px 40px;
        transition: all 0.4s;
        @include r.sp {
          font-size: 1.2rem;
          padding: 15px 20px;
        }
        &:hover {
          color: #c8161e;
          background: rgba($color: #c8161e, $alpha: 0.1);
          border: 1px solid transparent;
        }
      }
    }
    &__list {
      margin-top: 80px;
      @include r.sp {
        margin-top: 40px;
      }
      .service {
        &__item {
          display: flex;
          flex-direction: row-reverse;
          justify-content: space-between;
          align-items: center;
          gap: calc(80 / 1200 * 100%);
          border-top: 1px solid rgba($color: #111, $alpha: 0.5);
          padding: 80px 0;
          @include r.sp {
            flex-direction: column;
            align-items: initial;
            gap: 40px;
            padding: 40px 0;
          }
          &:last-child {
            border-bottom: 1px solid rgba($color: #111, $alpha: 0.5);
          }
          &--01 {
            display: block;
            .service {
              &__img {
                width: 100%;
                margin-top: 35px;
              }
              &__info {
                padding: 80px;
                @include r.pad {
                  padding: 0;
                  margin-top: 40px;
                }
              }
              &__txt {
                margin: 0;
              }
              &__flex {
                display: flex;
                justify-content: space-between;
                gap: 80px;
                margin-top: 60px;
                @include r.sp {
                  flex-direction: column;
                  gap: 0;
                  margin-top: 40px;
                }
                &__left {
                  width: calc((100% - 80px) / 2);
                  @include r.sp {
                    width: 100%;
                  }
                  .c-strength {
                    background: rgba($color: #c8161e, $alpha: 0.1);
                    padding: 40px;
                    @include r.sp {
                      padding: 30px;
                    }
                    &__ttl {
                      font-size: 2.4rem;
                      font-weight: bold;
                      letter-spacing: 0.05em;
                      color: #c8161e;
                      font-size: 2rem;
                    }
                    &__list {
                      display: flex;
                      flex-direction: column;
                      gap: 15px;
                      margin-top: 20px;
                      li {
                        list-style-type: disc;
                        font-size: 1.6rem;
                        font-weight: bold;
                        line-height: calc(28 / 16);
                        color: #c8161e;
                        margin-left: 1rem;
                        @include r.sp {
                          margin-left: 2rem;
                        }
                        span {
                          color: #111;
                        }
                      }
                    }
                  }
                  .c-term {
                    margin-top: 60px;
                    @include r.sp {
                      margin-top: 40px;
                    }
                    &__ttl {
                      font-size: 2.4rem;
                      font-weight: bold;
                      line-height: calc(36 / 24);
                      letter-spacing: 0.05em;
                      color: #c8161e;
                      margin-bottom: 20px;
                      @include r.sp {
                        font-size: 2rem;
                      }
                    }
                    &__dl {
                      display: flex;
                      align-items: baseline;
                      gap: 20px;
                      font-size: 1.6rem;
                      font-weight: bold;
                      line-height: calc(28 / 16);
                      border-bottom: 1px solid rgba($color: #111, $alpha: 0.2);
                      padding: 20px 0 17px;
                      @include r.pad {
                        flex-wrap: wrap;
                      }
                      @include r.sp {
                        flex-direction: column;
                        align-items: initial;
                        gap: 12px;
                      }
                      &:first-of-type {
                        border-top: 1px solid rgba($color: #111, $alpha: 0.2);
                      }
                      dt {
                        width: 240px;
                        line-height: inherit;
                        @include r.sp {
                          width: 100%;
                        }
                      }
                      dd {
                        width: calc(100% - 240px);
                        line-height: inherit;
                        @include r.sp {
                          width: 100%;
                        }
                        span {
                          color: #c8161e;
                        }
                      }
                    }
                  }
                }
                .c-flow {
                  width: calc((100% - 80px) / 2);
                  @include r.sp {
                    width: 100%;
                    margin-top: 40px;
                  }
                  &__ttl {
                    font-size: 2.4rem;
                    font-weight: bold;
                    line-height: calc(36 / 24);
                    letter-spacing: 0.05em;
                    color: #c8161e;
                    @include r.sp {
                      font-size: 2rem;
                    }
                  }
                  &__container {
                    display: flex;
                    flex-direction: column;
                    gap: 30px;
                    margin-top: 20px;
                  }
                  &__item {
                    display: flex;
                    align-items: center;
                    gap: 30px;
                    border: 1px solid #111;
                    padding: 20px 20px 16px;
                    position: relative;
                    @include r.sp {
                      flex-direction: column;
                      gap: 10px;
                    }
                    &:not(:last-child) {
                      &:after {
                        content: '';
                        display: inline-block;
                        width: 20px;
                        height: 10px;
                        background-image: url(/assets/images/common/arrow-triangle.svg);
                        background-repeat: no-repeat;
                        background-size: contain;
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        transform: translate(-50%, calc(100% + 10px));
                      }
                    }
                  }
                  &__step {
                    flex-shrink: 0;
                    font-family: 'Barlow', sans-serif;
                    font-size: 1.6rem;
                    line-height: calc(20 / 16);
                    span {
                      font-size: 2.6rem;
                    }
                  }
                  &__txt {
                    font-size: 1.6rem;
                    font-weight: bold;
                    line-height: calc(28 / 16);
                    @include r.sp {
                      text-align: center;
                    }
                  }
                }
              }
            }
            .c-case {
              margin-top: 60px;
              @include r.sp {
                margin-top: 40px;
              }
              &__ttl {
                font-size: 2.4rem;
                font-weight: bold;
                line-height: calc(36 / 24);
                letter-spacing: 0.05em;
                color: #c8161e;
                @include r.sp {
                  font-size: 2rem;
                }
              }
              &__container {
                display: flex;
                flex-direction: column;
                gap: 20px;
                margin-top: 20px;
              }
              &__item {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 70px;
                background: #f6f8f8;
                padding: 40px;
                @include r.sp {
                  flex-direction: column;
                  justify-content: center;
                  gap: 20px;
                  padding: 30px;
                }
                &__ttl {
                  font-size: 2rem;
                  font-weight: bold;
                  line-height: calc(3 / 2);
                  @include r.sp {
                    text-align: center;
                  }
                  span {
                    display: inline-block;
                    font-size: 1.6rem;
                    font-weight: bold;
                    @include r.pc {
                      &:before {
                        content: '［';
                      }
                      &:after {
                        content: '］';
                      }
                    }
                    @include r.sp {
                      font-size: 1.4rem;
                    }
                  }
                }
                &__txt {
                  font-size: 1.6rem;
                  font-weight: bold;
                  line-height: calc(28 / 16);
                  margin-top: 20px;
                }
              }
              &__img {
                width: 60px;
                height: 60px;
                flex-shrink: 0;
              }
              &__info {
                max-width: 780px;
              }
            }
          }
          &--05 {
            display: block;
            .c-ttl2 {
              line-height: 1;
            }
          }
          &__list {
            display: flex;
            flex-wrap: wrap;
            column-gap: 40px;
            row-gap: 80px;
            margin-top: 80px;
            @include r.sp {
              gap: 40px 15px;
              margin-top: 40px;
            }
            .c-option {
              &__item {
                width: calc((100% - 120px) / 4);
                @include r.sp {
                  width: calc((100% - 15px) / 2);
                }
              }
              &__img {
                background: #f6f8f8;
                padding: 60px;
                @include r.pad {
                  padding: 40px;
                }
                @include r.sp {
                  padding: 25px;
                }
                img {
                  width: 60px;
                  height: 60px;
                  margin: 0 auto;
                  @include r.sp {
                    width: 50px;
                    height: 50px;
                  }
                }
              }
              &__ttl {
                font-size: 1.6rem;
                font-weight: bold;
                line-height: calc(28 / 16);
                margin-top: 20px;
                @include r.sp {
                  margin-top: 10px;
                }
              }
            }
          }
        }
        &__txt {
          font-size: 1.6rem;
          font-weight: bold;
          line-height: 2;
          margin-top: 40px;
          @include r.sp {
            margin: 0;
          }
        }
        &__link {
          display: inline-flex;
          align-items: center;
          gap: 12px;
          font-weight: bold;
          border: 1px solid #000;
          border-radius: 40px;
          padding: 20px 40px;
          margin-top: 66px;
          @include r.sp {
            width: 158px;
            gap: 10px;
            font-size: 1.2rem;
            padding: 17px 20px;
            margin: 0 auto;
          }
          &:after {
            content: '→';
          }
          &:hover {
            color: #c8161e;
            background: rgba($color: #c8161e, $alpha: 0.1);
            border-color: transparent;
          }
        }
        &__img {
          flex-shrink: 0;
          width: calc(600 / 1200 * 100%);
          @include r.sp {
            width: 100%;
          }
        }
      }
    }
    &__contact {
      text-align: center;
      margin: 80px auto 0;
      @include r.sp {
        margin-top: 40px;
      }
      &__link {
        display: inline-flex;
        align-items: center;
        font-size: 2rem;
        font-weight: bold;
        line-height: calc(3 / 2);
        letter-spacing: 0.05em;
        color: #fff;
        background: #111;
        border-radius: 60px;
        padding: 40px 80px;
        transition: all 0.4s;
        @include r.sp {
          width: 100%;
          justify-content: center;
          font-size: 1.6rem;
          line-height: calc(24 / 16);
          padding: 20px;
        }
        &:before {
          content: '';
          display: inline-block;
          width: 40px;
          height: 40px;
          background-image: url(/assets/images/common/mail.svg);
          background-repeat: no-repeat;
          background-size: contain;
          margin-right: 10px;
          @include r.sp {
            width: 20px;
            height: 20px;
          }
        }
        &:hover {
          background: #c8161e;
        }
      }
    }
  }
  &4 {
    background: #fff;
    padding: 160px 0;
    @include r.sp {
      padding: 60px 0;
    }
    .inner {
      display: flex;
      justify-content: space-between;
      gap: 80px;
      @include r.pad {
        flex-direction: column;
        gap: 40px;
      }
    }
    .c-ttl1 {
      flex-shrink: 0;
    }
    &__info {
      max-width: 800px;
      padding-top: 20px;
      @include r.sp {
        padding-top: 0;
      }
      .c-company {
        &__txt {
          font-size: 1.6rem;
          font-weight: bold;
          line-height: 2;
        }
        &__dl {
          display: flex;
          align-items: baseline;
          gap: 20px;
          font-size: 1.6rem;
          font-weight: bold;
          line-height: calc(28 / 16);
          border-bottom: 1px solid rgba($color: #111, $alpha: 0.2);
          padding: 20px 0;
          @include r.sp {
            flex-direction: column;
          }
          &:first-of-type {
            border-top: 1px solid rgba($color: #111, $alpha: 0.2);
            margin-top: 107px;
            @include r.pad {
              margin-top: 67px;
            }
          }
          dt {
            width: 160px;
            line-height: inherit;
            @include r.sp {
              width: 100%;
            }
          }
          dd {
            width: calc(100% - 160px);
            line-height: inherit;
            @include r.sp {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
